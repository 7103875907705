<template>
  <div>
    <div class="page-body">
      <title-bar title="单位维护">
        <el-button type="default" @click="$router.back()" icon="fa fa-angle-left" :loading="loading">
          返回
        </el-button>
      </title-bar>
      <el-form ref="editForm" :model="record" :rules="rules"
               label-width="120px" style="width: 600px;margin: auto">
        <el-form-item label="单位名称" prop="orgName">
          <el-input type="text" v-model="record.orgName" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="上级单位" prop="parentName">
          <org-input v-model="record.parentId"></org-input>
        </el-form-item>
        <el-form-item label="单位性质" prop="orgType">
          <code-select code-type="orgType" v-model="record.orgType"></code-select>
        </el-form-item>
<!--        <el-form-item label="单位层级" prop="orgLevel">-->
<!--          <code-select code-type="orgLevel" v-model="record.orgLevel"></code-select>-->
<!--        </el-form-item>-->
        <el-form-item label="所属区域" prop="areaCodes">
          <area-cascader code-type="areaCode" v-model="record.areaCodes" :props="{ checkStrictly: true }"></area-cascader>
        </el-form-item>
        <el-form-item label="启用状态" prop="valid">
          <el-switch v-model="record.valid"></el-switch>
        </el-form-item>
      </el-form>
        <div class="button-container">
          <el-button type="primary" @click="save">
            <i class="fa fa-save"></i>
            保存
          </el-button>
          <el-button type="default" @click="$router.back()">
            <i class="fa fa-chevron-left"></i>
            取消
          </el-button>
        </div>
    </div>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar";
import TablePagination from "@/components/base/TablePagination";
import CodeSelect from "@/components/base/CodeSelect";
import Store from "@/utils/Store";
import AreaCascader from "@/components/base/AreaCascader";
import OrgInput from "@/components/base/OrgInput";

export default {
  components: {OrgInput, AreaCascader, CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  data() {
    return {
      orgId: "",
      record: {
        valid: true
      },
      rules: {
        orgName: [{required:true,message:'单位名称不能为空',trigger:'blur'}],
        orgType: [{required:true,message:'单位性质不能为空',trigger:'change'}],
        orgLevel: [{required:true,message:'单位层级不能为空',trigger:'change'}],
        areaCodes: [{required:true,message:'所属区域不能为空',trigger:'change'}]
      }
    }
  },
  created() {
    this.orgId = this.$route.query.orgId;
    if(!this.orgId) {
      return;
    }
    this.loading = true;
    this.postForm("/org/getById", {
      orgId: this.orgId
    }).then(dr => {
      this.record = dr.bean || {};
      if (this.record.provCode) {
        this.record.areaCodes = [];
        this.record.areaCodes.push(this.record.provCode);
      }
      if (this.record.cityCode) {
        this.record.areaCodes.push(this.record.cityCode);
      }
      if (this.record.townCode) {
        this.record.areaCodes.push(this.record.townCode);
      }
    }).catch( e => {
      this.$message.error(e.msg);
    }).finally(()=>{
      this.loading = false;
    })
  },
  methods: {
    save() {
      this.$refs["editForm"].validate(ok => {
        if(!ok) {
          return;
        }
        this.record.provCode = this.record.areaCodes[0];
        if(this.record.areaCodes.length>1) {
          this.record.cityCode = this.record.areaCodes[1];
        }
        if(this.record.areaCodes.length>2) {
          this.record.townCode = this.record.areaCodes[2];
        }
        this.loading = true;
        this.postForm("/org/save",this.record, true).then(dr => {
          this.record = dr.bean;
          this.$router.back();
        }).finally(dr => {
          this.loading = false;
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
